import _base from "./base";
var exports = {};

/* tslint:disable */

/* eslint-disable */

/**
 * OpenAI API
 * APIs for sampling from and fine-tuning language models
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = exports && exports.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createRequestFunction = exports.toPathString = exports.serializeDataIfNeeded = exports.setSearchParams = exports.setOAuthToObject = exports.setBearerAuthToObject = exports.setBasicAuthToObject = exports.setApiKeyToObject = exports.assertParamExists = exports.DUMMY_BASE_URL = void 0;
const base_1 = _base;
/**
 *
 * @export
 */

exports.DUMMY_BASE_URL = "https://example.com";
/**
 *
 * @throws {RequiredError}
 * @export
 */

exports.assertParamExists = function (functionName, paramName, paramValue) {
  if (paramValue === null || paramValue === undefined) {
    throw new base_1.RequiredError(paramName, `Required parameter ${paramName} was null or undefined when calling ${functionName}.`);
  }
};
/**
 *
 * @export
 */


exports.setApiKeyToObject = function (object, keyParamName, configuration) {
  return __awaiter(this, void 0, void 0, function* () {
    if (configuration && configuration.apiKey) {
      const localVarApiKeyValue = typeof configuration.apiKey === "function" ? yield configuration.apiKey(keyParamName) : yield configuration.apiKey;
      object[keyParamName] = localVarApiKeyValue;
    }
  });
};
/**
 *
 * @export
 */


exports.setBasicAuthToObject = function (object, configuration) {
  if (configuration && (configuration.username || configuration.password)) {
    object["auth"] = {
      username: configuration.username,
      password: configuration.password
    };
  }
};
/**
 *
 * @export
 */


exports.setBearerAuthToObject = function (object, configuration) {
  return __awaiter(this, void 0, void 0, function* () {
    if (configuration && configuration.accessToken) {
      const accessToken = typeof configuration.accessToken === "function" ? yield configuration.accessToken() : yield configuration.accessToken;
      object["Authorization"] = "Bearer " + accessToken;
    }
  });
};
/**
 *
 * @export
 */


exports.setOAuthToObject = function (object, name, scopes, configuration) {
  return __awaiter(this, void 0, void 0, function* () {
    if (configuration && configuration.accessToken) {
      const localVarAccessTokenValue = typeof configuration.accessToken === "function" ? yield configuration.accessToken(name, scopes) : yield configuration.accessToken;
      object["Authorization"] = "Bearer " + localVarAccessTokenValue;
    }
  });
};

function setFlattenedQueryParams(urlSearchParams, parameter, key = "") {
  if (typeof parameter === "object") {
    if (Array.isArray(parameter)) {
      parameter.forEach(item => setFlattenedQueryParams(urlSearchParams, item, key));
    } else {
      Object.keys(parameter).forEach(currentKey => setFlattenedQueryParams(urlSearchParams, parameter[currentKey], `${key}${key !== "" ? "." : ""}${currentKey}`));
    }
  } else {
    if (urlSearchParams.has(key)) {
      urlSearchParams.append(key, parameter);
    } else {
      urlSearchParams.set(key, parameter);
    }
  }
}
/**
 *
 * @export
 */


exports.setSearchParams = function (url, ...objects) {
  const searchParams = new URLSearchParams(url.search);
  setFlattenedQueryParams(searchParams, objects);
  url.search = searchParams.toString();
};
/**
 *
 * @export
 */


exports.serializeDataIfNeeded = function (value, requestOptions, configuration) {
  const nonString = typeof value !== "string";
  const needsSerialization = nonString && configuration && configuration.isJsonMime ? configuration.isJsonMime(requestOptions.headers["Content-Type"]) : nonString;
  return needsSerialization ? JSON.stringify(value !== undefined ? value : {}) : value || "";
};
/**
 *
 * @export
 */


exports.toPathString = function (url) {
  return url.pathname + url.search + url.hash;
};
/**
 *
 * @export
 */


exports.createRequestFunction = function (axiosArgs, globalAxios, BASE_PATH, configuration) {
  return (axios = globalAxios, basePath = BASE_PATH) => {
    const axiosRequestArgs = Object.assign(Object.assign({}, axiosArgs.options), {
      url: ((configuration === null || configuration === void 0 ? void 0 : configuration.basePath) || basePath) + axiosArgs.url
    });
    return axios.request(axiosRequestArgs);
  };
};

export default exports;